        /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
        /* Core CSS required for Ionic components to work properly */
        
        @import "~@ionic/angular/css/core.css";
        /* Basic CSS for apps built with Ionic */
        
        @import "~@ionic/angular/css/normalize.css";
        @import "~@ionic/angular/css/structure.css";
        @import "~@ionic/angular/css/typography.css";
        @import '~@ionic/angular/css/display.css';
        /* Optional CSS utils that can be commented out */
        
        @import "~@ionic/angular/css/padding.css";
        @import "~@ionic/angular/css/float-elements.css";
        @import "~@ionic/angular/css/text-alignment.css";
        @import "~@ionic/angular/css/text-transformation.css";
        @import "~@ionic/angular/css/flex-utils.css";
         :root {
            --foreground-default: #08090a;
            --foreground-secondary: #41474d;
            --foreground-tertiary: #797c80;
            --foreground-quaternary: #f4faff;
            --foreground-light: #41474d;
            // --background-default: # #f4faff;
            --background-default: #red;
            --background-secondary: #a3b9cc;
            --background-tertiary: #5c7d99;
            --background-light: #ffffff;
            --primary-default: #5dfdcb;
            --primary-dark: #24b286;
            --primary-light: #b2ffe7;
            --error-default: #ef3e36;
            --error-dark: #800600;
            --error-light: #ffcecc;
            /* Shadows */
            --background-tertiary-shadow: 0 1px 3px 0 rgba(92, 125, 153, 0.5);
        }